// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-views-article-js": () => import("./../../../src/views/article.js" /* webpackChunkName: "component---src-views-article-js" */),
  "component---src-views-contacts-js": () => import("./../../../src/views/contacts.js" /* webpackChunkName: "component---src-views-contacts-js" */),
  "component---src-views-holy-place-js": () => import("./../../../src/views/holy-place.js" /* webpackChunkName: "component---src-views-holy-place-js" */),
  "component---src-views-index-js": () => import("./../../../src/views/index.js" /* webpackChunkName: "component---src-views-index-js" */),
  "component---src-views-monastery-js": () => import("./../../../src/views/monastery.js" /* webpackChunkName: "component---src-views-monastery-js" */),
  "component---src-views-news-js": () => import("./../../../src/views/news.js" /* webpackChunkName: "component---src-views-news-js" */),
  "component---src-views-podvorie-behavior-js": () => import("./../../../src/views/podvorie/behavior.js" /* webpackChunkName: "component---src-views-podvorie-behavior-js" */),
  "component---src-views-podvorie-gallery-js": () => import("./../../../src/views/podvorie/gallery.js" /* webpackChunkName: "component---src-views-podvorie-gallery-js" */),
  "component---src-views-podvorie-history-js": () => import("./../../../src/views/podvorie/history.js" /* webpackChunkName: "component---src-views-podvorie-history-js" */),
  "component---src-views-podvorie-timetable-js": () => import("./../../../src/views/podvorie/timetable.js" /* webpackChunkName: "component---src-views-podvorie-timetable-js" */),
  "component---src-views-poetries-js": () => import("./../../../src/views/poetries.js" /* webpackChunkName: "component---src-views-poetries-js" */),
  "component---src-views-poetry-js": () => import("./../../../src/views/poetry.js" /* webpackChunkName: "component---src-views-poetry-js" */),
  "component---src-views-shrine-places-js": () => import("./../../../src/views/shrine-places.js" /* webpackChunkName: "component---src-views-shrine-places-js" */)
}

